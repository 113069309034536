import React from "react";

const Constructionupdate = () => {
  return (
    <>
      <section class="update_section">
        <div class="side_spacing section_spacing">
          <h2 class="text-center title_spacing">
            ALTITUDE CONSTRUCTION UPDATE | OCT 2024
          </h2>
          <h5 class="text-center">
            AFTER SHATTERING SALES RECORDS, WE ARE GEARING UP TO SET THE HIGHEST
            BENCHMARK IN DELIVERY!
          </h5>
          <div class="youtube-video-2">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/BdKiUx34tl8?si=j1YogUc0__OO2CSI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
        <div class="side_spacing section_spacing pt-0">
          <h2 class="text-center text-uppercase title_spacing">
            3D Walkthrough Vs <br class="d-md-none d-block" /> Actual shot on site
          </h2>
          <h5 class="text-center text-uppercase">
            Shattering the Boundaries Between Imagination and Realty!
          </h5>
          <div class="row shot mt-3 mt-md-3">
            <div class="col-md-6 mb-md-0 mb-3">
              <iframe width="560" height="315"
                src="https://www.youtube.com/embed/tQBmzXobO8Q?si=ivYIjHef4UoM3g9H&rel=0&modestbranding=1&showinfo=0&iv_load_policy=3"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div class="col-md-6">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/RSQL34I2bVs?si=tPammYt0aFkF86E6&rel=0&modestbranding=1&showinfo=0&iv_load_policy=3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Constructionupdate;
