import React from "react";

const Disclaimerfooter = () => {
  return (
    <>
      <div class="footer-text-container side_spacing">
        <p>&copy; Copyrights 2025 Official VTP Realty Website. All Rights Reserved.</p>
        <p>
          <a href="/privacy-policy">Privacy Policy</a>&nbsp; | &nbsp;
          <button
            data-bs-toggle="modal"
            data-bs-target="#disclaimerModal"
            className="disc-btn"
          >
            Disclaimer
          </button>
        </p>
        <div
          className="modal fade"
          id="disclaimerModal"
          tabindex="-1"
          aria-labelledby="disclaimerModal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-scrollable modal-fullscreen-sm-down">
            <div className="modal-content bg-black">
              <div className=" modal-header bg-black text-white">
                <h5 className="modal-title" id="disclaimerModal">
                  Disclaimer
                </h5>

                <button
                  type="button"
                  className="btn-close modal-close-btn btn-close-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body bg-black">
                <p>
                  This material is solely for informative purposes only. The
                  plans, layouts, specifications, renders, imagery and all other
                  details herein are indicative and the developer/owner reserves
                  the rights to change any or all of these in the interest of
                  the development. Certain fittings/options may be available in
                  limited units only or available at an additional price and are
                  not part of the standard unit. All brands mentioned herein are
                  subject to change with equivalent or better brand(s) at the
                  sole discretion of the project architect/management. The
                  dimensions and/or areas stated in the plans are measured on
                  the basis of the unfinished surface using the polyline method
                  and do not reflect the reduction in dimensions on account of
                  the finishes being installed. Further, the variance of +/- 3%
                  in the unit carpet area and/or unfinished dimensions is
                  possible due to design and construction variances. The plans
                  contained herein are typical unit/floor plans & the purchaser
                  must verify the exact plan and orientation of their unit
                  before purchase. This collateral does not constitute an offer
                  and/or contract of any type between the
                  developer/promoter/owner and the recipient, all intending
                  purchaser/s in this project shall be governed by the terms and
                  conditions envisaged under the Real Estate Regulation and
                  Development Act 2016. The copyrights of this content are owned
                  by VTP REALTY and no part of this content can be used or
                  extracted without the prior written consent of the brand.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Disclaimerfooter;
