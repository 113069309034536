import React, { useRef } from "react";
import Banner from "../components/Banner";
import Enquirynow from "../components/Enquirynow";
import Projecthighlights from "../components/Projecthighlights";
import ChooseAltitude from "../components/ChooseAltitude";
import Aim from "../components/Aim";
import Location from "../components/Location";
import Gallery from "../components/Gallery";
import Commonarea from "../components/Commonarea";
import Management from "../components/Management";
import Availability from "../components/Availability";
import Constructionupdate from "../components/Constructionupdate";
import Landmarks from "../components/Landmarks";
import AltitudeEnquiryform from "../components/AltitudeEnquiryform";
import Brand from "../components/Brand";
import Header from "../components/Header";

const Home = () => {
  const thankYou = useRef();

  const download_brochure = () => {
    localStorage.clear();
    localStorage.setItem('downloadBrochure', 'yes');
  }
  const removeTokan = () => {
    localStorage.clear();
  }

  return (
    <>
      <Banner />
      <Header />
      <Brand />
      {/* Overview */}
      <section className="brochure_section">
        <div className="side_spacing section_spacing">
          <h2 className="text-center title_spacing">
            ALTITUDE – GRADE A CLASSIFIED COMMERCIAL PROJECT
          </h2>
          <p className="text-center">
            It’s Time For #TheAimChanger <br />
            Altitude, with it’s superior aesthetics and well-planned
            architecture, is truly a testament to what a business empire looks
            like when it reaches the epitome of glory. With your business acumen
            and foresightedness, this is a dream team to take on any challenges
            that the world may put forth. Designed especially for signature
            commercial establishments whether offices, showrooms or more;
            Altitude is also #TheAimChanger in every sense of the word because
            of it’s prime location. It is close to the Pune-Mumbai Expressway,
            10-15-minute drive from prominent locations like Hinjawadi, Pimpri,
            Chinchwad, Ravet, Aundh, Pimple Saudagar, Baner, Pashan, University
            Circle etc. The project that challenges the status quo and stands
            out from the rest of the commercial projects around. And the only
            project that is giving the much-deserved makeover to Wakad: <br />
            Altitude.
          </p>
          <div className="text-center">
            <button
              className="header-btn custom-btn"
              data-bs-toggle="modal"
              data-bs-target="#downloadbrochure"
              onClick={download_brochure}
            >
              DOWNLOAD BROCHURE
            </button>
          </div>

          <div class="modal fade"
            id="downloadbrochure"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body bg-black">
                  <button
                    type="button"
                    className="btn-close modal-close-btn btn-close-white"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={thankYou}
                    onClick={removeTokan}
                  ></button>
                  <Enquirynow showCallbackDropdown={true} closePopup={thankYou} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Walkthrough */}
      <div className="youtube-video">
        <iframe
          src="https://www.youtube.com/embed/Bb2v6w9gviw?rel=0"
          title="ALTITUDE Walkthrough - Commercial Project by VTP Realty at Wakad, Pune"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
      <Projecthighlights />
      <ChooseAltitude />
      <Aim />
      <Location />
      <Availability />
      {/* Leasing Support */}
      <section className="support_section">
        <div className="side_spacing">
          <h2 className="text-center text-white">LEASING SUPPORT</h2>
          <h3 className="text-white text-center title_spacing">
            Leasing support will be provided by VTP Realty
          </h3>
          <p className="text-center text-white mb-0">
            VTP Realty leaves no stone unturned to create all its commercial
            projects into high decibel and footfall zones by cherry picking the
            top-notch brands as tenants for our retail and business spaces. Our
            dedicated leasing desk scouts the best brands for your property. The
            leasing, sourcing and transaction assistance is provided as a value
            added service.
          </p>
        </div>
      </section>
      <Gallery />
      <Commonarea />
      <Management />
      {/* About VTP */}
      <section className="about_section side_spacing section_spacing">
        <h2 className="text-center">About VTP Realty</h2>
        <p className="text-center">
          VTP Realty is Pune’s No.1 Real Estate Development Brand Consistently for 6 Years in a Row. The undisputed leaders that have not only set new benchmarks in business numbers but with stellar delivery line-up. Ranked Top 10 in India in terms of sales turnover, VTP Realty is a well-known skyrocketing success story despite being a single city developer.
        </p>
        <p className="text-center mb-0">
          A brand that stands firmly on strong ethics and projects a trailblazing thought-leadership for the industry. The numerous awards winning brand prides itself in pioneering the MLA (Maximum Livable Area) Homes.
        </p>
      </section>
      <Landmarks />
      <Constructionupdate />
      <AltitudeEnquiryform />
      <div className="location-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7562.321238483489!2d73.767684!3d18.611844!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9ef66d937cf%3A0x395bdb818418c712!2sAltitude%20by%20VTP%20Realty!5e0!3m2!1sen!2sin!4v1721843988409!5m2!1sen!2sin"
          style={{ border: "0" }}
          title="location"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      {/* <PopupModal /> */}
    </>
  );
};

export default Home;