const Brand = () => {
    return (
        <section className="no1_sec_wrap">
            <div className="col-md-6">
                <img
                    data-aos="fade-up" data-aos-delay="1000" data-aos-duration="500"
                    alt="Properties In Pune | Real Estate Developers In Pune"
                    loading="lazy" width="1033" height="946"
                    className="no1_building aos-init aos-animate"
                    style={{ color: "transparent" }}
                    src="/images/building.webp" />
            </div>

            <img data-aos="fade-left" data-aos-delay="1500" data-aos-duration="500" alt="VTP Pune No1 Brand" loading="lazy"
                width="577" height="462" decoding="async" className="no1_copy aos-init aos-animate"
                style={{ color: "transparent" }} src="./images/no_1.webp" />
            <div>
                <svg className="svg_no1" viewBox="0 0 577.476 600" xmlns="http://www.w3.org/2000/svg">
                    <g id="Group_12644" dataName="Group 12644" transform="translate(1020 -3033)">
                        <g id="Group_5" dataName="Group 5" transform="translate(-644.733 3077.34)">
                            <g id="Group_12643" dataName="Group 12643" clipPath="url(#clip-path)">
                                <path className="animate_path1" id="_1" dataName="1"
                                    d="M142.838,477.265a72.662,72.662,0,0,1-5.576-27.5q-.015-1.094-.016-2.18V0q-2.627,3.568-5.164,6.915C118.972,23.325,80.5,64.973,12.425,87.892H65.6V446.847h0C64.242,511.761,0,523.66,0,523.66H202.21s-42.982-7.966-59.371-46.4">
                                </path>
                            </g>
                        </g>
                        <text id="_" className="animate_path2" dataName="#" transform="translate(-827 3597)"
                            fontFamily="Beliau-Regular, Beliau">
                            <tspan x="-192.157" y="0">#</tspan>
                        </text>
                    </g>
                </svg>
            </div>
        </section>
    )
}

export default Brand;