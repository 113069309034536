import React from "react";
import Disclaimerfooter from "./Disclaimerfooter";

const Footer = () => {
  return (
    <>
      <div className="footer-new-desc side_spacing section_spacing d-none">
        <h3>
          Find Your Perfect Dream Home <br className="d-md-none d-block"/> with VTP Realty
        </h3>
        <p>
          Explore a range of premium residential projects in Pune by VTP Realty, designed to cater to your lifestyle and aspirations. From contemporary apartments to elegant simplex and duplex flats in Pune , our offerings redefine modern living.
        </p>
        <p>
          With exciting new launch projects in Pune , our developments are designed to provide seamless connectivity and world-class amenities. Whether you’re seeking flats for sale in Pune for your family or as an investment, we have options that combine comfort and convenience.
        </p>
        <p>
          Our meticulously crafted apartments in Pune are designed to blend modern aesthetics with practical functionality, ensuring a vibrant and fulfilling living experience. With VTP Realty, your dream home is closer than ever.
        </p>
      </div>
      <section id="footer" className="footer_section">
        <div className="side_spacing section_top_large">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="footer-scanner-img">
                <img
                  src="/images/footer-imgs/Altitude-scanner-img.png"
                  alt="QR Code 1"
                />
              </div>
              <h6 className="footer-scanner-text">Altitude</h6>
              <div className="d-flex align-items-center justify-content-center gap-1">
                <div>
                  <span className="mahalogo">
                    MahaRERA Reg. No: &nbsp; P52100024885
                  </span>
                </div>
              </div>
              <p className="footer_scan_text">
                For more details visit the <br /> website:&nbsp;
                <a href="https://maharera.mahaonline.gov.in" target="_blank" rel="noopener noreferrer">
                  maharera.mahaonline.gov.in
                </a>
              </p>
            </div>
          </div>
        </div>
        <Disclaimerfooter />
      </section>
    </>
  );
};

export default Footer;
