import React, { useLayoutEffect } from "react";
// import Header from "../components/Header";
import Footer from "../components/Footer";
import MobileContactBtn from "../components/MobileContactBtn";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Layout = () => {
  
	const { pathname } = useLocation();

	useLayoutEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

  return (
    <>
     <div className="App">
			  <ToastContainer />
        {/* <Header /> */}
        <Outlet />
        <Footer />
        <MobileContactBtn />
        <a
            class="whatsapp-btn-desk"
            target="_blank"
             rel="noopener noreferrer"
            href="https://api.whatsapp.com/send?phone=917219312222&text=Hi,%20I%20would%20like%20to%20know%20more%20about%20Altitude-Commerical%20Project!"
        >
            <i class="bi bi-whatsapp me-2"></i>
            <span>WhatsApp Us</span>
        </a>
        {/* <a
            class="whatsapp-btn-mobile"
            target="_blank"
             rel="noopener noreferrer"
            href="https://api.whatsapp.com/send?phone=917219312222&text=Hi,%20I%20would%20like%20to%20know%20more%20about%20Altitude-Commerical%20Project!"
        >
            <i class="bi bi-whatsapp"></i>
        </a> */}
     </div>
    </>
  );
};

export default Layout;