import { Turnstile } from '@marsidev/react-turnstile'

export default function Widget({ onSuccess, onError }) {

    const site_key = "0x4AAAAAAA0AbQIO6e7WOqXc";

    const handleSuccess = async (token) => {
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/turnstile-verify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ captchaToken: token })
            });

            const result = await response.json();

            if (result.status) {
                onSuccess && onSuccess(result.data);
            } else {
                onError && onError("Verification failed");
            }
        } catch (error) {
            onError && onError("Verification error");
        }
    };

    return (
        <Turnstile siteKey={site_key}
            onSuccess={handleSuccess}
            onError={onError}
        />
    )
}