import React from "react";
import Slider from "react-slick";

import Enquirynow from "./Enquirynow";

const Banner = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: true,
    nextArrow: (
      <div className="slick-next">
        <i className="bi bi-chevron-right"></i>
      </div>
    ),
    prevArrow: (
      <div className="slick-prev">
        <i className="bi bi-chevron-left"></i>
      </div>
    ),
  };
  return (
    <section className="banner_section first-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-9 col-md-7 col-12 px-0 desktop-banner">
            <Slider {...settings}>
              {/* <a href="https://doosridiwali.vtprealty.in/" target="_blank" rel="noreferrer">
                  <img src="/images/Banners/ban-0.webp" alt="banner" />
                </a> */}
              <img src="/images/Banners/Altitude-banner-1.webp" alt="banner" />
              <img src="/images/Banners/Altitude-banner-2.webp" alt="banner" />
              <img src="/images/Banners/Altitude-banner-3.webp" alt="banner" />
            </Slider>
          </div>
          <div className="col-lg-9 col-md-7 col-12 px-0 mobile-banner">
            <Slider {...settings}>
              {/* <a href="https://doosridiwali.vtprealty.in/" target="_blank" rel="noreferrer">
                  <img clas src="/images/Banners/mob-ban-0.webp" alt="banner" />
                </a> */}
              <img clas src="/images/Banners/Altitude-banner-Mob2.webp" alt="banner" />
              <img clas src="/images/Banners/Altitude-banner-Mob2.webp" alt="banner" />
              <img clas src="/images/Banners/Altitude-banner-Mob3.webp" alt="banner" />
            </Slider>
          </div>
          <div className="col-lg-3 col-md-5 col-12 px-0 form-container">
            <Enquirynow showCallbackDropdown={false} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
