import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleSticky = () => {
      const firstSection = document.querySelector(".first-section");
      if (firstSection) {
        const topBoundary = firstSection.offsetHeight;
        const isMobile = window.innerWidth <= 999;
        if (isMobile) {
          setIsSticky(true);
        } else {
          setIsSticky(window.scrollY >= topBoundary);
        }
      }
    };

    handleSticky();

    window.addEventListener("scroll", handleSticky);
    return () => {
      window.removeEventListener("scroll", handleSticky);
    };
  }, []);

  return (
    <>
      <header id="header" className={`header header_section ${isSticky ? "sticky" : ""}`}>
        <div className="header-main-container">
          <div>
            <Link className="navbar-brand-2 py-0" href="/" onClick={scrollToTop}>
              <img src="/images/VTPaltitude-logo-1.webp" alt="logo" />
            </Link>
          </div>
          <div className="header-btn-main">
            <ul class="header-btn-container">
              <li>
                <a href="tel:08065206017" class="header-btn custom-btn">
                  <i className="bi bi-telephone"></i>
                  &nbsp;08065206017
                </a>
              </li>
              <li>
                <a
                  href="#downloadbrochure"
                  className="header-btn custom-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#downloadbrochure"
                  role="button"
                >
                  ENQUIRY NOW
                </a>
              </li>
              <li>
                <Link className="navbar-brand-2 py-0 test" href="/" onClick={scrollToTop}>
                  <img src="/images/vtp-realty.webp" alt="logo" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
