import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CountryDropdown from "./CountryDropdown";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { isValidPhoneNumber } from 'libphonenumber-js';
import Widget from "./TurnstileWidget";

const Enquirynow = ({ showCallbackDropdown, onClick, closePopup, isModalOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  // eslint-disable-next-line no-unused-vars
  const [otherInfo, setOtherInfo] = useState({
    gclid: searchParams.get('gclid') || "",
    sfcid: searchParams.get('sfcid') || "",
    sfid: searchParams.get('sfid') || "",
    utm_source: searchParams.get('utm_source') || "",
    utm_medium: searchParams.get('utm_medium') || "",
    utm_campaign: searchParams.get('utm_campaign') || "",
    utm_term: searchParams.get("utm_term") || "",
    utm_device: searchParams.get("utm_device") || "",
    gad_source: searchParams.get("gad_source") || "",
    fbclid: searchParams.get("fbclid") || "",
    sk_referrer: searchParams.get('sk_referrer') || ""
  });


  const [captchaValidated, setCaptchaValidated] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const getValidationSchema = (showCallbackDropdown) => Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[A-Za-z\s'-]+$/, "Letters only please")
      .required("First name is required!"),
    last_name: Yup.string()
      .matches(/^[A-Za-z\s'-]+$/, "Letters only please")
      .required("Last name is required!"),
    mobile: Yup.string()
      .required("Mobile number is required!")
      .matches(/^\d+$/, "Invalid phone number")
      .test('is-valid-phone', 'Invalid phone number', function (value) {
        const { country_code } = this.parent;
        if (!value) return false;
        const cleanedValue = value.replace(/\D/g, '');

        if (!cleanedValue) {
          return this.createError({ message: 'Phone number cannot be empty after cleaning.' });
        }
        const fullNumber = `${country_code}${cleanedValue}`;
        const isValid = isValidPhoneNumber(fullNumber);
        if (!isValid) {
          return this.createError({ message: 'Invalid number' });
        }
        return isValid;
      }),

    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required!"),
    interest: Yup.string().required("Please select an interest!"),
    country_code: Yup.string().required("Country code is required!"),
    term_condition: Yup.boolean()
      .oneOf([true], "You must accept the terms and conditions")
      .required("Acceptance of terms is required!"),
    callback: showCallbackDropdown
      ? Yup.string().required("Please select a callback time slot")
      : Yup.string().notRequired(),
  });

  const handlerSubmit = async (formVal) => {
    if (!captchaValidated) {
      toast.error("Please complete the CAPTCHA to proceed.");
      return;
    }

    let CampaignID = "7015j000000Pub1";
    let secondary_source = "google";

    if (formVal.utm_source === "facebook") {
      CampaignID = "7015j000000PukD";
      secondary_source = formVal.utm_source;
    }

    if (
      formVal.utm_source === "facebook" &&
      formVal.utm_campaign === "facebook-altitude"
    ) {
      CampaignID = "7015j000000PukD";
      secondary_source = formVal.utm_source;
    }
    if (formVal.utm_source === "linkedin") {
      CampaignID = "7015j000000Punh";
      secondary_source = formVal.utm_source;
    }
    if (formVal.sfid) {
      CampaignID = formVal.sfid;
      secondary_source = formVal.utm_source;
    }

    if (formVal.sk_referrer === "international") {
      if (formVal.utm_source === "google") {
        CampaignID = "7015j000000Puaw";
      }
      if (formVal.utm_source === "facebook") {
        CampaignID = "7015j000000Puk8";
      }
      if (formVal.utm_source === "linkedin") {
        CampaignID = "7015j000000Punr";
      }
      secondary_source = formVal.utm_source;
    }

    if (formVal.sk_referrer === "rom") {
      if (formVal.utm_source === "google") {
        CampaignID = "7015j000000Puey";
      }
      if (formVal.utm_source === "facebook") {
        CampaignID = "7015j000000Puk3";
      }
      if (formVal.utm_source === "linkedin") {
        CampaignID = "7015j000000Puo0";
      }
      secondary_source = formVal.utm_source;
    }

    let CampaignNewID = null;
    if (formVal.utm_source !== "google") {
      CampaignNewID = CampaignID;
    }

    const formData = {
      formData: {
        FirstName: formVal.first_name,
        LastName: formVal.last_name,
        Email: formVal.email,
        ProjectName: "Commercial West",
        SubProjectName: "Altitude",
        Company: "",
        CampaignId: CampaignNewID,
        PrimarySource: "Digital",
        SecondarySource: secondary_source,
        UtmCampaign: formVal.utm_campaign,
        UtmMedium: formVal.utm_medium,
        UtmSource: formVal.utm_source,
        UtmDevice: formVal.utm_device,
        UtmTerm: formVal.utm_term,
        GADsource: formVal.gad_source,
        FBCLID: formVal.fbclid,
        InterestedFor: formVal.interest,
        CountryCode: formVal.country_code,
        Mobile: formVal.mobile,
        GCLID: formVal.gclid,
        Comment: " ",
        captchaToken,
      },
    };

    const loadingToast = toast.loading("Loading...", {
      position: toast.POSITION.TOP_CENTER,
    });

    const config = {
      method: "post",
      url: process.env.REACT_APP_BASE_URL + "/salesforce/add",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      const response = await axios(config);
      toast.dismiss(loadingToast);
      let downloadBrochure = "";
      if (closePopup) {
        downloadBrochure = localStorage.getItem("downloadBrochure");
        closePopup.current.click();
      }

      if (response.data.status === true) {
        localStorage.setItem("downloadBrochure", downloadBrochure);
        localStorage.setItem("formData", JSON.stringify(formData.formData));
        navigate("/thank-you");
      }
    } catch (error) {
      toast.dismiss(loadingToast);
      toast.error("Request failed!");
    }
  };

  const handleCaptchaSuccess = (token) => {
    setCaptchaToken(token);
    setCaptchaValidated(true);
  };

  const handleCaptchaError = () => {
    setCaptchaValidated(false);
  };

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        country_code: "+91",
        mobile: "",
        email: "",
        interest: "",
        term_condition: true,
        callback: "",
        gclid: otherInfo.gclid,
        sfcid: otherInfo.sfcid,
        sfid: otherInfo.sfid,
        utm_source: otherInfo.utm_source,
        utm_medium: otherInfo.utm_medium,
        utm_campaign: otherInfo.utm_campaign,
        utm_device: otherInfo.utm_device,
        utm_term: otherInfo.utm_term,
        gad_source: otherInfo.gad_source,
        fbclid: otherInfo.fbclid,
        sk_referrer: otherInfo.sk_referrer,
        download_brochure: ""
      }}
      validationSchema={getValidationSchema(showCallbackDropdown)}
      onSubmit={async (values, { setSubmitting }) => {
        handlerSubmit(values);
      }}
    >
      {({ isSubmitting, setFieldValue }) => {
        return (
          <Form>
            <div className="banner_form">
              <h2 className="section-title mb-4">
                <img src="/images/Altitude Logo.svg" alt="logo" style={{ height: '30px', width: 'auto' }} />
              </h2>
              <div className="row">
                <div className="col-md-6 col-12 mb-3">
                  <Field type="hidden" id="gclid" name="gclid" />
                  <Field type="hidden" id="sfcid" name="sfcid" />
                  <Field type="hidden" id="sfid" name="sfid" />
                  <Field type="hidden" id="utm_source" name="utm_source" />
                  <Field type="hidden" id="utm_medium" name="utm_medium" />
                  <Field type="hidden" id="utm_campaign" name="utm_campaign" />
                  <Field type="hidden" id="utm_device" name="utm_device" />
                  <Field type="hidden" id="utm_term" name="utm_term" />
                  <Field type="hidden" id="gad_source" name="gad_source" />
                  <Field type="hidden" id="fbclid" name="fbclid" />
                  <Field type="hidden" id="sk_referrer" name="sk_referrer" />
                  <Field type="hidden" id="download_brochure" name="download_brochure" />

                  <Field
                    type="text"
                    id="first_name"
                    name="first_name"
                    className="form-control form-field"
                    placeholder="First Name*"
                  />
                  <ErrorMessage name="first_name" component="span" className="error" />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <Field
                    type="text"
                    id="last_name"
                    name="last_name"
                    className="form-control form-field"
                    placeholder="Last Name*"
                  />
                  <ErrorMessage name="last_name" component="span" className="error" />
                </div>
                <div className="col-md-5 col-4 mb-3">
                  <CountryDropdown />
                </div>
                <div className="col-md-7 col-8 mb-3 pl-0">
                  <Field
                    type="text"
                    id="mobile"
                    name="mobile"
                    className="form-control form-field"
                    placeholder="Phone*"
                  />
                  <ErrorMessage name="mobile" component="span" className="error" />
                </div>
                <div className="col-md-12 col-12 mb-3">
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    className="form-control form-field"
                    placeholder="Email*"
                  />
                  <ErrorMessage name="email" component="span" className="error" />
                </div>
                <div className="col-md-12 col-12 mb-3">
                  <Field
                    as="select"
                    className="form-select form-field"
                    name="interest"
                  >
                    <option value="">Interested For*</option>
                    <option value="showrooms">Showrooms</option>
                    <option value="offices">Offices</option>
                  </Field>
                  <ErrorMessage name="interest" component="span" className="error" />
                </div>
                {showCallbackDropdown && (
                  <div className="col-md-12 col-12 mb-3">
                    <Field
                      as="select"
                      className="form-select form-field"
                      name="callback"
                    >
                      <option value="">
                        Which time slot is preferable to you for a callback?
                      </option>
                      <option value="Call me immediately">
                        Call me immediately
                      </option>
                      <option value="7 am - 10 am">7 am - 10 am </option>
                      <option value="10 am – 2 pm">10 am – 2 pm </option>
                      <option value="2 pm – 7 pm">2 pm – 7 pm </option>
                      <option value="7 pm – 9 pm">7 pm – 9 pm </option>
                      <option value="9 pm – 12 midnight">
                        9 pm – 12 midnight
                      </option>
                    </Field>
                    <ErrorMessage name="callback" component="span" className="error" />
                  </div>
                )}
                <div className="col-md-7 col-12 mb-3">
                  <Widget onSuccess={handleCaptchaSuccess} onError={handleCaptchaError} />
                </div>
                <div className="col-md-12 col-12 mb-3">
                  <div className="form-check">
                    <Field
                      className="form-check-input"
                      type="checkbox"
                      name="term_condition"
                    />
                    <label className="form-check-label text-white">
                      I hereby authorize to send notification on SMS, Calls, RCS, WhatsApp.
                    </label>
                  </div>
                  <ErrorMessage name="term_condition" component="span" className="error" />
                </div>
                <div className="d-flex justify-content-center">
                  <button type="submit" className="btn form-btn" disabled={isSubmitting}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  );
};

export default Enquirynow;
