    import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
    import Layout from '../layout/layout';
    import Home from '../pages/Home';
    import NotFound from '../pages/NotFound';
    import Privacy from '../pages/Privacy';
    import ThankYou from '../pages/ThankYou';
    import TagManager from 'react-gtm-module';

    const trackPageView = (pathname, email, phone) => {
        const eventKey = `tracked-${pathname}`;

        if (!sessionStorage.getItem(eventKey)) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'page_view',
                    page: {
                        url: window.location.href,
                        title: document.title,
                        path: pathname,
                    },
                    userData: {
                        email: email || null,
                        phone: phone || null,
                    },
                },
            });
            console.log(`DataLayer pushed for: ${pathname}`);
            sessionStorage.setItem(eventKey, true);
        } else {
            console.log(`DataLayer already pushed for: ${pathname}`);
        }
    };

    const routeLoader = async ({ request }) => {
        const url = new URL(request.url);

        const formData = JSON.parse(localStorage.getItem("formData"));
        const email = formData?.Email || null;
        const phone = formData?.Mobile || null;

        trackPageView(url.pathname, email, phone);

        return null;
    };

    const router = () =>
        createBrowserRouter(
            createRoutesFromElements(
                <>
                    <Route element={<Layout />}>
                        <Route
                            index
                            path="/"
                            element={<Home />}
                            loader={routeLoader}
                        />
                        <Route
                            path="/privacy-policy"
                            element={<Privacy />}
                            loader={routeLoader}
                        />
                        <Route
                            path="/thank-you"
                            element={<ThankYou />}
                            loader={routeLoader}
                        />
                        <Route path="/privacy-policy/index.html" element={<Navigate to="/privacy-policy" />} />
                        <Route path="/slide-page/home/" element={<Navigate to="/" />} />
                        <Route path="/slide/home2/" element={<Navigate to="/" />} />
                        <Route path="/slide/home/" element={<Navigate to="/" />} />
                        <Route path="/home-21-sep-23/" element={<Navigate to="/" />} />
                        <Route path="/?s=:search_term_string" element={<Navigate to="/" />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </>
            )
        );

    export default router;
