import React from "react";

const Availability = () => {
  const download_brochure = () => {
    localStorage.clear();
    localStorage.setItem('downloadBrochure', 'yes');
  }
  return (
    <>
      <section className="availability_section unit-plans">
        <div className="side_spacing section_spacing">
          <div className="unit-title mb-md-3 mb-0">
            <div className="text">
              <h2 className="title_spacing">
                UNIT PLANS
              </h2>
              <p class="">
                Our luxurious apartments are a blend of modern architecture and thoughtful design. Each residence boasts spacious layouts, premium finishes, and an abundance of natural light, creating an inviting and warm ambiance.
              </p>
            </div>
            <div className="price-tag">
              <div className="tag-title">
                <p className="m-0">Starting at <span className="tag-price m-0 fw-bold"> Rs.1 Cr</span></p>
              </div>
            </div>
          </div>
          <div className="table-main">
            <table className="unit-table">
              <thead>
                <tr>
                  <th className="border-lr">Type</th>
                  <th>Carpet Area (sq.ft.)</th>
                  <th className="border-rr">Product Cost (₹)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="config">Showroom</td>
                  <td className="area">1087 onward</td>
                  <td className="cost">
                    <button class="cost-btn" data-bs-toggle="modal" data-bs-target="#downloadbrochure">
                      Request Price
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.541 10.8938L14.824 6.17676L14.117 6.88376L18.733 11.5008H4V12.5008H18.735L14.117 17.1178L14.824 17.8248L19.541 13.1088C19.837 12.8128 20 12.4188 20 12.0008C20 11.5828 19.837 11.1888 19.541 10.8948V10.8938Z" fill="#151515" stroke="#151515" stroke-width="0.35" />
                      </svg>
                    </button>
                  </td>
                </tr>
                <tr className="border-0">
                  <td className="config">Office</td>
                  <td className="area">480.72 – 673.61</td>
                  <td className="cost">
                    <button class="cost-btn" data-bs-toggle="modal" data-bs-target="#downloadbrochure">
                      Request Price
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.541 10.8938L14.824 6.17676L14.117 6.88376L18.733 11.5008H4V12.5008H18.735L14.117 17.1178L14.824 17.8248L19.541 13.1088C19.837 12.8128 20 12.4188 20 12.0008C20 11.5828 19.837 11.1888 19.541 10.8948V10.8938Z" fill="#151515" stroke="#151515" stroke-width="0.35" />
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="unit-bg-wrapper">
          <img src="/images/blueprint-left.webp" alt="" className="unit-left unit-bg" />
          <img src="/images/blueprint-right.webp" alt="" className="unit-right unit-bg" />
        </div>
      </section>
      <section className="availability_section">
        <div className="side_spacing section_spacing">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-6">
              <div className="price-box">
                <div className="box-image">
                  <img src="/images/unitPrice/2bhk.webp" alt="2bhk" />
                  <div className="box-cta">
                    <button
                      className="header-btn custom-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#downloadbrochure"
                      onClick={download_brochure}
                    >
                      ON REQUEST
                    </button>
                  </div>
                </div>
                <div className="unit-desc">
                  <p className="m-0 text-nowrap text-center">Showroom - 1087 sq.ft</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="price-box">
                <div className="box-image">
                  <img src="/images/unitPrice/3bhk-1.webp" alt="3bhk-1" />
                  <div className="box-cta">
                    <button
                      className="header-btn custom-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#downloadbrochure"
                      onClick={download_brochure}
                    >
                      ON REQUEST
                    </button>
                  </div>
                </div>
                <div className="unit-desc">
                  <p className="m-0 text-nowrap text-center">Office - 480.72 - 673.61 sq.ft</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Availability;
